var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[(_vm.$resize && _vm.$mq.above(_vm.window.mobileSize))?_c('v-layout',{attrs:{"row":"","wrap":""}},[_vm._l((_vm.cards),function(card){return _c('v-flex',{key:card.index,staticClass:"scrollbarMenu",attrs:{"xs12":"","md6":"","lg3":""}},[_c('v-card',{staticClass:"contrast",attrs:{"hover":"","to":{
          name: 'inseriratendimento',
          query: {
            solicitation: card.title,
            type: card.type,
            request: card.request,
          },
        }}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-icon',{staticClass:"white--text",attrs:{"medium":""}},[_vm._v(_vm._s(card.icon))]),_c('v-list-item-content',{staticClass:"ml-3 white--text"},[_c('v-list-item-subtitle',{staticStyle:{"color":"rgba(255, 255, 255, 0.8)"}},[_vm._v(_vm._s(card.subtitle.toUpperCase()))]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(_vm._s(card.title))])],1)],1)],1)],1)}),_c('atendimento-listar')],2):_c('v-row',{staticClass:"fitContainer pa-1",attrs:{"dense":""}},[_c('v-tabs',{attrs:{"background-color":"transparent","grow":"","centered":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":"#tab-create"}},[_vm._v("Solicitar")]),_c('v-tab',{attrs:{"href":"#tab-list"}},[_vm._v("Histórico")])],1),_c('v-tabs-items',{staticStyle:{"width":"100%"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{attrs:{"value":'tab-create'}},_vm._l((_vm.cards),function(card){return _c('v-col',{key:card.index,staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"contrast",attrs:{"elevation":"2","to":{
              name: 'inseriratendimento',
              query: {
                solicitation: card.title,
                type: card.type,
                request: card.request,
              },
            }}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-icon',{staticClass:"white--text",attrs:{"medium":""}},[_vm._v(_vm._s(card.icon))]),_c('v-list-item-content',{staticClass:"ml-3 white--text"},[_c('v-list-item-subtitle',{staticStyle:{"color":"rgba(255, 255, 255, 0.8)"}},[_vm._v(_vm._s(card.subtitle.toUpperCase()))]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(_vm._s(card.title))])],1)],1)],1)],1)}),1),_c('v-tab-item',{attrs:{"value":'tab-list'}},[_c('atendimento-listar')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }