<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-textarea style="white-space: pre-wrap;" hide-details class="pb-5" label="Comentário" v-model="caseComment.body"
                        placeholder="Digite aqui seu comentário" rows="5" auto-grow outlined/>
          </v-col>
          <v-col cols="12">
            <form-file-upload
                    ref="customFileUpload"
                    :maximum="10"
                    :limit-file-size="12"
                    @update="setAttachments"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer/>
        <v-btn large color="secondary" light @click="cancelar()" class="black--text">Cancelar</v-btn>
        <v-btn large
               color="primary"
               :loading="busy"
               :disabled="busy || (attachments.length == 0  && !caseComment.body)"
               @click="createEvent"
        >Inserir</v-btn>
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-btn block
                 large
                 color="primary"
                 :loading="busy"
                 :disabled="busy || (attachments.length == 0  && !caseComment.body)"
                 @click="createEvent"
          >Inserir</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn block large color="secondary" light @click="cancelar()" class="black--text">Cancelar</v-btn>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
  import {mapMutations, mapState} from "vuex";
  import ContentVersionService from "../../services/odoo/ContentVersionService";
  import moment from "moment";
  import ComentsService from "../../services/odoo/ComentsService";
  import FormFileUpload from "../generic-upload/FormFileUpload";
  import CaseService from "../../services/odoo/CaseService";

  export default {
    components: { FormFileUpload },
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    data: () => ({
      defaultErrorObject: { text: "Erro ao inserir", type: "error" },
      attachments: [],
      busy: false,
      caseComment: {
        name: moment(new Date()).format("DD/MM/YYYY - HH:mm"),
        res_id: null,
        body: null,
        model: "helpdesk.ticket",
        message_type: "comment",
        email_from: null,
      },
    }),
    created() {
      this._contentVersionService = new ContentVersionService();
      this._caseService = new CaseService();
    },
    beforeMount() {
      this._comentsService = new ComentsService();
      this.caseComment.res_id = this.id;
    },
    computed:{
      ...mapState(["window"]),
    },
    methods: {
      ...mapMutations(["showmsg", "loading"]),
      setAttachments(attachments) {
        this.attachments = attachments;
      },
      async createEvent() {
        // this.busy = true;
        this.loading(true);
        await this.createCaseEvent(this.id)
                .then(response => {
                  this.$router.go(-1);
                })
                .finally(() => (this.loading(false)));
      },
      cancelar() {
        this.$router.go(-1);
      },
      getUserEmail() {
        return this.$util.getSession() && this.$util.getSession().email ? this.$util.getSession().email : null;
      },
      async createCaseEvent(id) {
        let emptyFile = false;
        let emptyFileNames = "";
        const files = this.attachments.map(file => {

          let fileName = file.name.substring(0, file.name.lastIndexOf('.')) || '';

          if (!(file.body.split(",")[1])) {
            emptyFile = true;
            emptyFileNames+= file.name + "<br/>"
          }

          let f = {
            name: fileName,
            datas: file.body.split(",")[1],
            description: "CLIENT",
          };

          return f;
        });

        if(emptyFile){
          this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
          throw "Empty File"
        }

        this.caseComment.email_from = this.getUserEmail();

        const caseCommentAux = Object.assign({}, this.caseComment);
        if(caseCommentAux.body) {
          caseCommentAux.body = caseCommentAux.body.replaceAll('\n', '<br/>');
        }

        let attachments = {
          entityId: id,
          caseComment: caseCommentAux,
          files: files
        }

        await this._caseService.CreateCaseEvent(attachments).catch(someError => {
          throw someError;
        });
      }
    }
  };
</script>

