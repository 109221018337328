<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <GenericFileInput
          label="Anexar documentos"
          :limitFileSize="12"
          @update="setAttachments"
        />
      </v-container>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer />
        <v-btn
          large
          color="secondary"
          light
          @click="cancelar()"
          class="black--text"
          >Cancelar</v-btn
        >
        <v-btn
          large
          color="primary"
          :loading="busy"
          :disabled="busy || attachments.length == 0"
          @click="createAttachment"
          >Inserir</v-btn
        >
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-btn
            block
            large
            color="primary"
            :loading="busy"
            :disabled="busy || attachments.length == 0"
            @click="createAttachment"
            >Inserir</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn
            block
            large
            color="secondary"
            light
            @click="cancelar()"
            class="black--text"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import GenericFileInput from "@/components/generic-file-input/index.vue";
import ContentVersionService from "../../services/odoo/ContentVersionService";

export default {
  components: { GenericFileInput },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    attachments: [],
    busy: false,
  }),
  created() {
    this._contentVersionService = new ContentVersionService();
  },
  computed: {
    ...mapState(["window"]),
  },
  methods: {
    ...mapMutations(["showmsg"]),
    setAttachments(attachments) {
      this.attachments = attachments;
    },
    createAttachment() {
      this.busy = true;
      this.addAttachmentsToCase(this.id)
        .then(async (response) => {
          this.$router.go(-1);
        })
        .catch(() => this.showmsg(this.defaultErrorObject))
        .finally(() => (this.busy = false));
    },
    cancelar() {
      this.$router.go(-1);
    },
    async addAttachmentsToCase(id) {
      let emptyFile = false;
      let emptyFileNames = "";
      const files = this.attachments.map((file) => {
        let fileName = file.name.substring(0, file.name.lastIndexOf(".")) || "";

        if (!file.body.split(",")[1]) {
          emptyFile = true;
          emptyFileNames += file.name + "<br/>";
        }

        let f = {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };

        return f;
      });

      if (emptyFile) {
        this.showmsg({
          text:
            "Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>" +
            emptyFileNames +
            "<b>",
          type: "error",
        });
        throw "Empty File";
      }

      let attachments = {
        entityId: id,
        files: files,
      };

      await this._caseService
        .CreateCaseEvent(attachments)
        .catch((someError) => {
          throw someError;
        });
    },
  },
};
</script>

