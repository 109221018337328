<template>
  <v-layout wrap>
    <v-card :loading="busy"/>
    <template v-for="benefitType in benefitsnew">
      <v-flex
          sm12
          md6
          v-for="contract in benefitType.items"
          class="primary--text pa-5"
          :key="contract.id"
          @click="openContract(contract)"
      >
        <v-card hover class="elevation-5">
          <v-card-title
              pa-0
              ma-0
              class="contrast white--text title elevation-5 mb-5"
          >
            <div class="pa-0 ma-0 row">
              <div class="pa-0 ma-0 col text-left text-truncate">
                {{ contract.carrier ? contract.carrier : contract.comercialName }} -
                {{
                  benefitType.benefit
                      ? benefitType.benefit
                      : contract.benefitName
                }}
              </div>
              <v-icon medium class="left" color="white"
              >fas fa-{{ benefitType.icon }}
              </v-icon
              >
            </div>
          </v-card-title>

          <v-card-text class="pa-2">
            <v-row>
              <v-col cols="12" md="6" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.accountName"
                    label="Empresa"
                    readonly
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.policy"
                    label="Nº Contrato"
                    readonly
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.accountCNPJ"
                    label="CNPJ"
                    readonly
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.dueDateInvoice"
                    label="Vencimento de Prêmio"
                    readonly
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.startDate"
                    label="Inicio Vigência"
                    readonly
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.carrierDataCancelation"
                    label="Fim Vigência"
                    readonly
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.copart"
                    label="Coparticipação"
                    readonly
                    outlined
                    class="hidden-sm-and-down"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="px-5 py-0 ma-0">
                <v-text-field
                    :value="contract.cutOffDate"
                    label="Corte"
                    readonly
                    outlined
                    class="hidden-sm-and-down"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-5 py-0 ma-0 primary--text text-center">
                <v-flex> Clique para detalhar</v-flex>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";

import ContractService from "../../services/odoo/ContractService";

export default {
  props: {
    whereClause: null,
  },
  data: () => ({
    describe: null,
    busy: false,
    benefitsnew: [
      {
        description: "Saúde",
        icon: "heartbeat",
        items: [],
      },
      {
        description: "Odontológico",
        icon: "tooth",
        items: [],
      },
      {
        description: "Vida",
        icon: "user-shield",
        items: [],
      },
      {
        description: "Medicamento",
        icon: "capsules",
        items: [],
      },
      {
        description: "Outros",
        icon: "",
        items: [],
      },
    ],
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    configureContracts(records) {
      /* records = records.filter((f) => f.subcontract == false); */
      records.forEach((contract) =>
          this.categorizeContract({
            id: contract.id,
            benefitName: contract.benefit_id ? contract.benefit_id : "",
            beneficio: contract.benefit_id ? contract.benefit_id : "",
            carrier: contract.carrier_id ? contract.carrier_id.xipp_commercial_name : "",
            consultingName: contract.consulting ? this.getStringValue(contract.consulting.name) : "",
            accountName: contract.contract_owner_id ? this.getStringValue(contract.contract_owner_id.name) : "",
            accountCNPJ: contract.cnpj,
            status: contract.status,
            policy: contract.policy,
            comercialName: this.getStringValue(contract.contract_owner_id.xipp_commercial_name),
            startDate: this.formatDate(contract.start_date),
            cutOffDate: contract.cut_off_date,
            carrierDataCancelation: this.formatDate(contract.carrier_data_cancelation),
            copart: this.getStringValue(contract.moderator_variable),
            benefitReferenceName: "", //contract.Benefit__r.ReferenceName__c,
            dueDateInvoice: contract.due_date_invoice
          })
      );
      this.orderCategorizedContracts();
    },
    openContract(contract) {
      this.$router.push({
        name: "contractDetail",
        params: {id: contract.id},
      });
    },
    categorizeContract(contract) {
      let defaultCategoryName = "Outros";
      let name = contract.benefitName.replace("Acidente Pessoal", "Vida");

      if (this.benefitsnew.some((s) => s.description == name)) {
        this.benefitsnew
            .filter((f) => f.description == name)[0]
            .items.push(contract);
      } else {
        this.benefitsnew
            .filter((f) => f.description == defaultCategoryName)[0]
            .items.push(contract);
      }
    },
    orderCategorizedContracts() {
      this.benefitsnew.forEach((benefitGroup) => {
        benefitGroup.items.sort((contractA, contractB) => {
          return contractA.carrier.localeCompare(contractB.carrier) ||
              contractA.accountName.localeCompare(contractB.accountName);
        });
      })
    },
    async buscasContratos() {
      this.busy = false;
      const params = new URLSearchParams();
      params.append('subcontract', false);
      params.append('fields', ['id', 'benefit_id', 'carrier_id', 'consulting.name',
        'contract_owner_id', 'cnpj', 'status', 'policy', 'contract_owner_id',
        'start_date', 'cut_off_date', 'carrier_data_cancelation', 'moderator_variable', 'due_date_invoice',
        'subcontract']);
      params.append('status', this.whereClause);
      await this._contractservice
          .findContractsByFilters(params)
          .then((res) => {
            if (res.data) {
              this.configureContracts(res.data);
            }
          })
          .finally(() => (this.busy = false));
    },
    getIcon(name) {
      name = name.replace("Acidente Pessoal", "Vida");

      if (this.icons.some((s) => s.description == name)) {
        return this.icons.filter((s) => s.description == name)[0].icon;
      } else {
        return null;
      }
    },
    getStringValue(value) {
      return value ? value : '';
    },
    formatDate(date){
      return date ? Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(date)) : " ";
    }
  },
  async created() {
    try {
      this.loading(true);
      this._contractservice = new ContractService();
      await this.buscasContratos();
      this.loading(false);
    } finally {
      this.loading(false);
    }
  },
};
</script>

<style scoped>
.v-input {
  font-size: 1em;
  pointer-events: none;
}
</style>
