import { render, staticRenderFns } from "./menuLeft.vue?vue&type=template&id=8dc92920&scoped=true&"
import script from "./menuLeft.vue?vue&type=script&lang=js&"
export * from "./menuLeft.vue?vue&type=script&lang=js&"
import style0 from "./menuLeft.vue?vue&type=style&index=0&id=8dc92920&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dc92920",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VCol,VIcon,VList,VListGroup,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VNavigationDrawer})
