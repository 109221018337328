<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-form id="ticketForm" ref="ticketForm" @submit.prevent="createTicket">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                :value="caseData.subject"
                label="Solicitação"
                readonly
                outlined
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="contracts"
                :loading="isLoadingContracts"
                v-model="caseData.contractID"
                label="Contrato"
                placeholder="Selecione um contrato"
                outlined
                :rules="[rules.required]"
                @change="changeContract"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="caseData.attendee"
                label="Atendido"
                outlined
                placeholder="Se necessário, informe o nome do colaborador a ser atendido com respectivo CPF."
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="caseData.description"
                label="Solicitação"
                placeholder="Descreva sua Solicitação"
                :rules="[rules.required]"
                auto-grow
                outlined
                hide-details
                class="pb-5"
              />
            </v-col>
            <v-col cols="12">
              <form-file-upload
                ref="customFileUpload"
                :maximum="10"
                :limit-file-size="12"
                @update="setAttachments"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer />
          <v-col cols="3" v-if="viewControlActivated">
              <v-select
                      :items="viewerPermissionTypes"
                      v-model="caseData.viewerPermissionTypeCode"
                      label="Quem pode visualizar?"
                      placeholder="Selecione quem pode visualizar."
                      item-value="code"
                      item-text="description"
                      outlined
                      :rules="[rules.required]"
                      clearable
                      @change="changeViewerPermissionType"
              />
          </v-col>
          <v-col cols="3" v-if="viewControlActivated">
              <v-select
                      v-if="caseData.viewerPermissionTypeCode==='g'"
                      :items="users"
                      item-value="id"
                      item-text="name"
                      v-model="caseData.users"
                      label="Usuários"
                      placeholder="Selecione um ou mais usuários."
                      outlined
                      :rules="[rules.required]"
                      clearable
                      multiple
                      :loading="groupLoading"
              />
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              large
              color="secondary"
              light
              @click="cancelar()"
              class="black--text"
              >Cancelar</v-btn
            >&nbsp;&nbsp;
            <v-btn
              large
              color="primary"
              type="submit"
              form="ticketForm"
              :loading="busy"
              :disabled="busy"
              >Solicitar</v-btn
            >
              <br><br>
          </v-col>
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-spacer />
          <v-col cols="3" v-if="viewControlActivated">
            <v-select
                :items="viewerPermissionTypes"
                v-model="caseData.viewerPermissionTypeCode"
                label="Quem pode visualizar?"
                placeholder="Selecione quem pode visualizar."
                item-value="code"
                item-text="description"
                outlined
                :rules="[rules.required]"
                clearable
            />
          </v-col>
          <v-col cols="3" v-if="viewControlActivated">
            <v-select
                v-if="caseData.viewerPermissionTypeCode==='g'"
                :items="users"
                item-value="id"
                item-text="name"
                v-model="caseData.users"
                label="Usuários"
                placeholder="Selecione um ou mais usuários."
                outlined
                :rules="[rules.required]"
                clearable
                multiple
                :loading="groupLoading"
            />
          </v-col>
          <v-col cols="6" class="text-right">
          <v-btn
            block
            large
            color="primary"
            type="submit"
            form="ticketForm"
            :loading="busy"
            :disabled="busy"
            >Solicitar</v-btn
          >
          <v-btn
            block
            large
            color="secondary"
            light
            @click="cancelar()"
            class="black--text"
            >Cancelar</v-btn
          >
            <br><br>
          </v-col>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import { formrules } from "./../../common/formrules";
import CaseService from "../../services/odoo/CaseService";
import ContractService from "../../services/odoo/ContractService";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
import {findAllUsersByFinancialGroupAndContract} from "@/common/securityserver";

export default {
  name: "AtendimentoInserir",
  components: { FormFileUpload },
  props: {
    solicitacao: null,
    type: null,
    request: null,
  },
  data: () => ({
    defaultErrorObject: { text: "Erro ao inserir", type: "error" },
    caseData: {
      contractID: null,
      accountID: null,
      subject: null,
      description: "",
      attachments: [],
      type: null,
      request: null,
      origin: "rh_protegido",
      visible_to_the_customer: true,
      attendee: null,
      viewerPermissionTypeCode: "m",
      users: []
    },
    isLoadingContracts: false,
    contracts: [],
    viewerPermissionTypes: [{code:'m', description:"Minha Organização"},{code:'p', description:"Privado (somente eu)"},{code:'g', description:"Grupo"}],
    users: [],
    viewControlActivated: false,
    rules: formrules,
    busy: false,
    groupLoading: false
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    },
  },
  async created() {
    this._caseService = new CaseService();
    this._contractservice = new ContractService();
    this.loadQueryParameters()
  },
  async beforeMount() {
    var currentUser = this.$util.getSession();
    currentUser.profiles.forEach(profile => {
        if(profile.name=='Master' || profile.name=='Administrador' || profile.name=='Operacional'){
          this.viewControlActivated = true;
        }
    });
    this.getContracts();
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    setAttachments(attachments) {
      this.caseData.attachments = attachments;
    },
    async createTicket() {
      if (!this.$refs.ticketForm.validate()) {
        setTimeout(this.$refs.ticketForm.resetValidation, 3000);
        return;
      }

      let emptyFile = false;
      let emptyFileNames = "";

      // this.busy = true;
      this.loading(true);
      const configuredCaseData = {
        xipp_contract_id: {
          id: this.caseData.contractID
        },
        partner_id: {
          id: this.contracts.find(
              (contract) => contract.value === this.caseData.contractID
          ).partner_id
        },
        name: this.caseData.subject,
        description: this.caseData.description,
        xipp_origin_id: this.caseData.origin,
        visible_to_the_customer: this.caseData.visible_to_the_customer,
        ticket_type_id: this.caseData.type,
        xipp_request_id: this.caseData.request,
        xipp_attendee_name: this.caseData.attendee,
        xipp_email_web: this.user.email,
        xipp_viewer_permission_type_id: {"code": this.caseData.viewerPermissionTypeCode}
      };

      const attachments = this.caseData.attachments.map((file) => {
        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames+= file.name + "<br/>"
        }

        let fileName =
          file.name.substring(0, file.name.lastIndexOf(".")) || "";

        let f = {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };
        return f;
      });

      if(emptyFile){
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        this.loading(false);
        return;
      }

      let caseEvent = {
        caseObject: configuredCaseData,
        files: attachments,
        usersPermissionView: this.caseData.users
      };

      await this._caseService
        .CreateCaseWithAttachment(caseEvent)
        .then((res) => {
          this.$router.push({ name: "atendimento", params: { id: res.data } });
        })
        .catch(() => this.showmsg(this.defaultErrorObject))
        .finally(() => this.loading(false));
    },
    async getContracts() {
      this.isLoadingContracts = true;
      const params = new URLSearchParams();
      params.append('status', 'active')
      params.append('login', this.$util.getSession().email)
      params.append('fields', ['id','name','benefit_id','carrier_id','carrier_id.xipp_commercial_name',
        'policy','contract_owner_id','contract_owner_id.name'])
      this._contractservice.FindAllByFiltersV2(params).then((res) => {
      /* this._contractservice.FindByStatus('active', this.$util.getSession().email).then((res) => { */
        res.data.forEach((contract) => {
          var texto = contract.contract_owner_id ? contract.contract_owner_id.name : "";
          texto += contract.policy ? " - " + contract.policy : "";
          texto += contract.benefit_id ? " - " + contract.benefit_id : "";
          texto += contract.carrier_id && contract.carrier_id.xipp_commercial_name ? "/" + contract.carrier_id.xipp_commercial_name : "";

          if (contract.contract_owner_id) {
            this.contracts.push({
              partner_id: contract.contract_owner_id.id,
              value: contract.id,
              text: texto,
            });
          }
        });
      })
      .finally(() => {
        this.isLoadingContracts = false;
      });
    },
    cancelar() {
      this.$router.go(-1);
    },
    loadQueryParameters() {
      const { solicitation, type, request } = this.$route.query;
      this.caseData.subject = solicitation;
      this.caseData.type = type;
      this.caseData.request = request;
    },
    async changeContract(){
      this.users = [];
      this.groupLoading=true;
      this.users =  await findAllUsersByFinancialGroupAndContract(this.user.tokenSecurityService, this.caseData.contractID);
      this.groupLoading=false;
      this.users.sort( (u1, u2 ) => {
        if ( u1.name < u2.name){
          return -1;
        }
        if ( u1.name > u2.name ){
          return 1;
        }
        return 0;
      });
    },
    changeViewerPermissionType(){
      if(this.caseData.viewerPermissionTypeCode==='g'){
        if(this.caseData.contractID == null){
          this.showmsg({ text: 'Selecione um contrato para carregar os Usuários do grupo.', type: "warning" });
        }
      }
    }
  }
};
</script>
